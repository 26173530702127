import React, { createContext, useState, useContext } from 'react';

export const ScriptContext = createContext();

export const ScriptProvider = ({ children }) => {
    const [script, setScript] = useState([]);

    const updateScript = (newScript) => {
        if (Array.isArray(newScript)) {
            setScript(newScript);
        } else if (typeof newScript === 'string') {
            setScript(newScript.split('\n'));
        }
    };

    return (
        <ScriptContext.Provider value={{ script, updateScript }}>
            {children}
        </ScriptContext.Provider>
    );
};

export const useScript = () => {
    const context = useContext(ScriptContext);
    if (!context) {
        throw new Error('useScript must be used within a ScriptProvider');
    }
    return context;
};