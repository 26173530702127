import React, { useState } from 'react';
import { supabase } from '../lib/supabase';
import { Box, TextField, Button, Typography, Container } from '@mui/material';

const BetaSignup = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        if (!email) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        try {
            const { error } = await supabase
                .from('beta_signups')
                .insert([{ email }]);

            if (error) throw error;

            setSuccessMessage('Thank you! Expect to hear from us soon :)');
            setEmail('');
        } catch (error) {
            console.error('Error Submitting Form:', error);
            setErrorMessage('Something went wrong. Please try again later.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                minHeight: '100vh',
                backgroundColor: '#85a9ff',
                padding: { xs: 2, md: 4 },
            }}
        >
            {/* Left Side - Image */}
            <Box
                sx={{
                    flex: 1,
                    backgroundImage: 'url(/movie-pic.jpg)',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />

            {/* Right Side - Signup Form */}
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    borderRadius: { xs: '16px', md: '8px' },
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Container maxWidth="sm" sx={{ textAlign: 'left' }}>
                    {/* Welcome Heading */}
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            color: '#1f2937',
                            marginBottom: 1,
                        }}
                    >
                        Welcome to BeMyReader
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            marginBottom: 3,
                            color: '#4b5563',
                        }}
                    >
                        Be the first to know when we launch!
                    </Typography>

                    {/* Form */}
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Your Email Address"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{
                                marginBottom: 3,
                                backgroundColor: '#f9f9f9',
                                borderRadius: 1,
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: '#2563eb',
                                color: '#fff',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#1d4ed8',
                                },
                            }}
                        >
                            Notify Me
                        </Button>
                    </form>
                    {successMessage && (
                        <Typography
                            variant="body2"
                            sx={{ color: '#10b981', marginTop: 2 }}
                        >
                            {successMessage}
                        </Typography>
                    )}
                    {errorMessage && (
                        <Typography
                            variant="body2"
                            sx={{ color: '#ef4444', marginTop: 2 }}
                        >
                            {errorMessage}
                        </Typography>
                    )}
                </Container>
            </Box>
        </Box>
    );
};

export default BetaSignup;
